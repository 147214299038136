import React, { useState } from 'react';
import helper from '../helper';
import { fileUploadToS3 } from '../helper/utils';
import '../static/css/s3FileUpload.css'
import CapturePhoto from './CapturePhoto';
import { WebcamStreamCapture } from './RecordVideo';


export default function S3FileUpload({ cb, onClose, type }) {
    const [state, setState] = useState({ file: null, type: "image", openSS: false, openWebRecorder: false })
    const [loader, setLoader] = useState(false)
    const handleUpload = async () => {
        let dirName = type === "images" ? "dhad_tutor_pictures" : type === "video" ? "dhad_tutor_intro_video" : "dhad_file"
        if (type === "video") {
            if (parseFloat((state.file.size / 1024) / 1024).toFixed(1) > 15) {
                return helper.showMessage("File should not be greater than 15 MB")
            }
        }
        else if (type === "image") {
            if (parseFloat((state.file.size / 1024) / 1024).toFixed(1) > 5) {
                return helper.showMessage("File should not be greater than 5 MB")
            }
        }
        setLoader(true)
        const url = await fileUploadToS3(dirName, state.file)
        setLoader(false)
        cb(url)
    }

    return (
        <div className="popup-modal h-100 all-center ">
            <div className="popup-modal-body sm s3-fileupload">
                {state.file &&
                    <div className="p-2 all-center flex-column">
                        {type === "image" ?
                            <img style={{ maxWidth: '100%' }} src={URL.createObjectURL(state.file)} alt="file" /> :
                            type === "video" ?
                                <video style={{ maxWidth: '100%' }} src={URL.createObjectURL(state.file)} controls></video> : ""
                        }
                        <div>File size : <span>{parseFloat((state.file.size / 1024) / 1024).toFixed(2)} MB</span></div>
                    </div>}

                {!state.file && !state.openSS && type === "image" && <div className="select-file">
                    <label htmlFor="gallery" className='gallery'>
                        Select from Gallery
                        <input type="file" className='d-none' accept='image/*' name="" id="gallery" onChange={(e) => { setState({ ...state, file: e.target.files[0], type: "image" }) }} />
                    </label>
                    <label className='web-cam' onClick={() => setState({ ...state, openSS: true, type: "image" })}>
                        Capture from PC
                    </label>
                </div>}

                {!state.file && !state.openWebRecorder && type === "video" && <div className="select-file">
                    <label htmlFor="gallery" className='gallery'>
                        Select from Gallery
                        <input type="file" className='d-none' accept='video/*' name="" id="gallery" onChange={(e) => { setState({ ...state, file: e.target.files[0], type: "video" }) }} />
                    </label>
                    <label htmlFor="p" className='web-cam' onClick={() => setState({ ...state, openWebRecorder: true, type: "video" })}>
                        Capture from PC
                    </label>
                </div>}

                {state.openSS && <CapturePhoto onCapture={(file) => setState({ ...state, file, type: "image", openSS: false })} />}
                {state.openWebRecorder && <WebcamStreamCapture onCapture={(file) => setState({ ...state, file, type: "video", openWebRecorder: false })} />}

                {loader &&
                    <div class="progress mx-3">
                        <div className="progress-bar bg-info progress-bar-animated progress-bar-striped active" role="progressbar"
                            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}>
                            Please wait...
                        </div>
                    </div>
                }
                {!state.openSS && !state.openWebRecorder && <section className='my-2 all-center'>
                    {state.file && <button style={{ width: '120px' }} className='btn cancel-subscription f-fred mr-2' onClick={() => setState({ ...state, file: null })}>CLEAR</button>}
                    {state.file && <button style={{ width: '120px' }} className='btn btn-login mr-2' onClick={handleUpload}>UPLOAD</button>}
                    <button style={{ width: '120px' }} className='btn btn-tutor-profile' onClick={onClose}>CANCEL</button>
                </section>
                }


            </div>

        </div>
    )
}
