/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Menubar from './Menubar'
import moment from 'moment-timezone'
import { useHistory, useParams } from 'react-router-dom'
import UserPops from './UserPops'
import user from '../controller/user'
import helper from '../helper/index'
import avatar from '../static/images/avatar.png'
import store from '../redux/AppState'
import ReactStars from "react-rating-stars-component";
import ProgressBar from '../component/ProgressBar'
import firebasePush from '../helper/firebaseDb'
import tutorHelper from '../helper/tutor'
import AlertClassPop from '../component/AlertClassPop'
import ReferralClasses from './ReferralClasses'
import { FaBars, FaBell, FaEllipsisV } from 'react-icons/fa'
import SvgDownArrow from '../Icons/DownArrow'
import chatIcon from '../static/images/dashboard/Chat.svg'
import calendarIcon from '../static/images/dashboard/Calendar.svg'
import { socket } from '../config/socket'
import LanguageTranslator from '../component/LanguageTranslator'
import { useTranslation } from "react-i18next";
import { Tooltip, Menu } from '@mui/material'
import UserTour from '../webtour/UserTour'
import { userSteps } from '../webtour/steps'
export default function Header() {
    const { t } = useTranslation();
    const { page } = useParams()
    const history = useHistory()
    const storeData = useSelector(state => state)
    const [openMenu, setOpenMenu] = useState(false)
    const [state, setState] = useState({
        changePass: false,
        profilePic: null,
        country: null,
        showleftminutes: false,
        showUpcomingClass: false,
        upcomingClass:false
    })

    const [leftMinutes, setLeftMinutes] = useState({
        data: null,
        open: false,
        err: null
    })

    const [upcomingClassData, setUpcomingClassData] = useState([])
    const [nav, setNav] = useState({ open: false, ref: null })

    useEffect(() => {
        setState({ ...state, data: null, open: false, err: null })
        socketEnable()
        getProfile()
        getTodayClasses(helper.getid(), moment().format('YYYY-MM-DD'))
        getTodayLeftMinutes()
        firebasePush.getPushPermission()
        getPlan()
        return () => { }
    }, [storeData.loadWindow])

    const getProfile = async () => {
        await user.getMyProfile(helper.getid()).then((res) => {
            if (res.data.status) {
                store.setFlag(res.data.result && res.data.result.flag_uri)
                store.setProfilePic(res.data.result && res.data.result.profilePicture)
                store.setName(res.data.result && res.data.result.name)
                setState({ ...state, profilePic: res.data.result && res.data.result.profilePicture, country: res.data.result && res.data.result.country })
            }
        }).catch((err) => { })
    }

    const getTodayClasses = async (id, date) => {
        await user.getBookedClassesByDate(id, date).then((res) => {
            if (res.data.status) {
                let list = []
                res.data.result.forEach((data) => {
                    if (data.status === "Booked" || data.status == "Completed") {
                        list.push(data)
                    }
                })
                setUpcomingClassData(list)
            }
        }).catch((err) => { })
    }

    const getTodayLeftMinutes = async () => {
        await user.getLeftMinutes(helper.getid()).then((res) => {
            if (res.data.status) {
                  if(res.data.result){
                    store.setPlanMinutes(res.data.result.minutes_per_day)
                    store.setPlanLeftMinutes(res.data.result.available_minutes)
                    helper.setAvailMin(res.data.result.available_minutes.toString())
                    setLeftMinutes({ ...leftMinutes, data: res.data.result })
                }else{
                    store.setPlanMinutes(0)
                    store.setPlanLeftMinutes(0)
                    helper.setAvailMin(0)
                    setLeftMinutes({ ...leftMinutes, data: null })
                    setState({...state,showleftminutes  :false})
                }
                
            }
        }).catch((err) => { })
    }

    const resetOn = () => {
        const date = new Date()
        const nightDate = new Date(`${moment(date).format('YYYY-MM-DD')} 23:59:00`)
        var formated_startTime = moment(date, 'hh:mm:ss')
        var formated_endTime = moment(nightDate, 'hh:mm:ss')
        var dif = moment.duration(formated_endTime.diff(formated_startTime));
        var d = new Date();
        d.setDate(d.getDate() + (((1 + 7 - d.getDay()) % 7) || 7));
        return { time: tutorHelper.makeTwoDigit(dif.hours()) + "h " + tutorHelper.makeTwoDigit(dif.minutes()+1) + "m " + tutorHelper.makeTwoDigit(dif.seconds()+1) + "s ", week: moment(d).format('MMM DD, YYYY') }
    }

    const socketEnable = () => {
        socket.on(`beforeStudentClassPopup${helper.getid()}`, (result) => {
            store.setAlertClassPop({
                open: true,
                data: result,
                type: 'user'
            })
        })
    }

    const getPlan = async () => {
        await user.getMyPlan(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    if (res.data.result === "Please purchase a subscription plan") {
                        const data = {
                            isPurchase: false,
                            data: res.data.result
                        }
                        helper.savePlanDetails({ plandata: data })
                    } else {
                        const data = {
                            isPurchase: true,
                            data: res.data.result
                        }
                        helper.savePlanDetails({ plandata: data })
                    }

                }
            })
    }

    return (
        <>
            <div className="row gx-0 mx-0 mb-3 p-sticky tp-0 pt-3 bg-white" style={{ zIndex: 1 }}>
                <section className="col-lg-4 col-md-5 col-6 f-fred d-flex flex-column justify-content-center ">

                    {page === "home" ?
                        <div className="mb-1 fs-24  d-flex align-items-start">
                            <span>
                                <label htmlFor="web-sideMenu"><FaBars size={24}  className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                            </span>
                            <div className='d-lg-block d-md-block d-none mx-2'> {t('userDash.header.userDash')}
                            </div>
                        </div> : page === "calendar" ?

                            <div className="mb-1 fs-24  d-flex align-items-start">
                                <span>
                                    <label htmlFor="web-sideMenu"><FaBars size={24}  className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                    <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                </span>
                                <div className='d-lg-block d-md-block d-none mx-2'> {t('userDash.header.calendar')}
                                </div>
                            </div>
                            : page === "course" ?

                                <div className="mb-1 fs-24  d-flex align-items-start">
                                    <span>
                                        <label htmlFor="web-sideMenu"><FaBars size={24}  className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                        <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                    </span>
                                    <div className='d-lg-block d-md-block d-none mx-2'> {t('userDash.header.saydhadCourses')}
                                    </div>
                                </div>
                                : page === "tutor" || page === "tutor-by-course" || page === "tutor-by-referral" || page === "referral-tutor-profile" ?

                                    <div className="mb-1 fs-24  d-flex align-items-start">
                                        <span>
                                            <label htmlFor="web-sideMenu"><FaBars size={24}  className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                            <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                        </span>
                                        <div className='d-lg-block d-md-block d-none mx-2'> {t('userDash.header.saydhadTutor')} 
                                        </div>
                                    </div>
                                    : page === "chat" ?

                                        <div className="mb-1 fs-24  d-flex align-items-start">
                                            <span>
                                                <label htmlFor="web-sideMenu"><FaBars size={24}  className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                            </span>
                                            <div className='d-lg-block d-md-block d-none mx-2'> {t('userDash.header.yourChat')} 
                                            </div>
                                        </div>
                                        :

                                        page === "support" ?

                                            <div className="mb-1 fs-24  d-flex align-items-start">
                                                <span>
                                                    <label htmlFor="web-sideMenu"><FaBars size={24}  className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                    <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                </span>
                                                <div className='d-lg-block d-md-block d-none mx-2'> {t('userDash.header.userSupport')}
                                                </div>
                                            </div>
                                            :
                                            page === "notification" ?

                                                <div className="mb-1 fs-24  d-flex align-items-start">
                                                    <span>
                                                        <label htmlFor="web-sideMenu"><FaBars size={24}  className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                        <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                    </span>
                                                    <div className='d-lg-block d-md-block d-none mx-2'> {t('userDash.header.user')} <span className="tx-green">{t('userDash.header.notification')}</span>
                                                    </div>
                                                </div>
                                                :
                                                page === "profile" ?

                                                    <div className="mb-1 fs-24  d-flex align-items-start">
                                                        <span>
                                                            <label htmlFor="web-sideMenu"><FaBars size={24}  className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                            <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                        </span>
                                                        <div className='d-lg-block d-md-block d-none mx-2'> {t('userDash.header.my')} <span className="tx-green">{t('userDash.header.profile')}</span>
                                                        </div>
                                                    </div>

                                                    : page === "about" ?

                                                        <div className="mb-1 fs-24  d-flex align-items-start">
                                                            <span>
                                                                <label htmlFor="web-sideMenu"><FaBars size={24}  className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                                <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                            </span>
                                                            <div className='d-lg-block d-md-block d-none mx-2'> About <span className="tx-green">SayDhad</span>
                                                            </div>
                                                        </div>

                                                        : page === "terms-condition" ?

                                                            <div className="mb-1 fs-24  d-flex align-items-start">
                                                                <span>
                                                                    <label htmlFor="web-sideMenu"><FaBars size={24}  className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                                    <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                                </span>
                                                                <div className='d-lg-block d-md-block d-none mx-2'> Terms & Condition
                                                                </div>
                                                            </div>

                                                            : page === "privacy-policy" ?
                                                                <div className="mb-1 fs-24  d-flex align-items-start">
                                                                    <span>
                                                                        <label htmlFor="web-sideMenu"><FaBars size={24}  className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                                        <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                                    </span>
                                                                    <div className='d-lg-block d-md-block d-none mx-2'> Privacy policy
                                                                    </div>
                                                                </div>

                                                                : page === "subscription" ?

                                                                    <div className="mb-1 fs-24  d-flex align-items-start">
                                                                        <span>
                                                                            <label htmlFor="web-sideMenu"><FaBars size={24}  className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                                            <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                                        </span>
                                                                        <div className='d-lg-block d-md-block d-none mx-2'> {t('userDash.header.subPlan')}
                                                                        </div>
                                                                    </div>

                                                                    : page === "myprogress" ?
                                                                        <div className="mb-1 fs-24  d-flex align-items-start">
                                                                            <span>
                                                                                <label htmlFor="web-sideMenu"><FaBars size={24}  className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                                                <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                                            </span>
                                                                            <div className='d-lg-block d-md-block d-none mx-2'> {t('userDash.header.myprogress')} 
                                                                            </div>
                                                                        </div>


                                                                        : page === "schedule" ?

                                                                            <div className="mb-1 fs-24  d-flex align-items-start">
                                                                                <span>
                                                                                    <label htmlFor="web-sideMenu"><FaBars size={24}  className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                                                    <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                                                </span>
                                                                                <div className='d-lg-block d-md-block d-none mx-2'> {t('userDash.header.schedule')} <span className="tx-green">{t('userDash.header.class')} </span>
                                                                                </div>
                                                                            </div>

                                                                            : page === "referral" ?

                                                                                <div className="mb-1 fs-24  d-flex align-items-start">
                                                                                    <span>
                                                                                        <label htmlFor="web-sideMenu"><FaBars size={24}  className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                                                        <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                                                    </span>
                                                                                    <div className='d-lg-block d-md-block d-none mx-2'> {t('userDash.header.myReferral')}
                                                                                    </div>
                                                                                </div>


                                                                                : ''}
                                                                                
                    <p className={`${storeData.language === 'ar' && "text-right"} sub-heading lh-18  d-lg-block d-md-block d-none`}>{t('general.welcomeBack')} <span className='tx-green ms-900'>{storeData.name.split(" ").length > 0 ? storeData.name.split(" ")[0] : ""}</span></p>
                </section>

                <section className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-lg-start align-items-start   f-fred" onMouseLeave={() => setState({ ...state, showleftminutes: false, upcomingClass: false })}>
                    {/* Subscription button */}
                    {page !== 'subscription' && page !== 'profile' && <button id="step-subscribe" className='btn ls fs-14  cancel-subscription-hd br-1 px-2 mr-2' onMouseOver={() => setState({ ...state, showleftminutes: false, upcomingClass: false })} onClick={() => history.push('/user/dashboard/subscription')}>{t('general.subscribe')}</button>}

                    {/* Change password button */}
                    {page === "profile" &&
                        <button className='btn  fs-14 mr-2 change-password d-lg-inline d-md-inline d-none' onClick={() => setState({ ...state, changePass: true })}>{t('general.changePass')}</button>
                    }

                    {/* Today left minutes */}
                    {!state.err && leftMinutes.data && <div className='p-rel d-lg-block d-md-block d-none'>
                        <button id="step-leftminute" className='btn ms-700 mr-2 left-minutes-btn fs-14 px-2' style={{ height: '40px' }} onMouseOver={() => { setState({ ...state, showleftminutes: true, upcomingClass: false }); getTodayLeftMinutes() }}>{leftMinutes.data && leftMinutes.data.available_minutes} {t('userDash.header.min')}
                            <SvgDownArrow className="ml-2 mr-1" />
                        </button>
                        {
                            state.showleftminutes &&
                            <div onMouseLeave={() => setState({ ...state, showleftminutes: false, upcomingClass: false })} className='p-abs left-minute-popup p-2 mt-2'>
                                <div className='left-minute-card my-2 p-3'>
                                    <h4 className='ms-700'> {t('userDash.header.todayMin')}</h4>
                                    <p className='my-2 ms-500'>{leftMinutes.data && leftMinutes.data.available_minutes} {t('userDash.header.min')}</p>
                                    <div className='my-2'>
                                        <ProgressBar
                                            width="100%"
                                            height={"10px"}
                                            bg="#ffcb00"
                                            now={leftMinutes.data && (leftMinutes.data.available_minutes)}
                                            total={leftMinutes.data && leftMinutes.data.minutes_per_day}
                                        />
                                    </div>
                                    <p className='ms-500 fs-14 ltr'> {resetOn().time} until daily reset at 12:00 AM IST.</p>
                                    <p className='ms-500 fs-14 ltr'>Week resets Monday {resetOn().week} 12:00 AM</p>

                                </div>
                                <div className='left-minute-card my-2 p-3 ltr'>
                                    <h4 className='ms-700'> {t('userDash.header.currentPlan')}</h4>
                                    <p className='my-2 ms-500'> {t('userDash.header.validTill')} {leftMinutes.data && moment(leftMinutes.data.subscription_expiry_date).format('MMM DD, YYYY')}</p>
                                    <div className='d-flex justify-content-between align-items-center py-2 px-2 border-yellow'>
                                        <p className='ms-900'>{leftMinutes.data && leftMinutes.data.commitment_level}</p>
                                        <p className='ms-700 fs-14'>{leftMinutes.data && leftMinutes.data.minutes_per_day + " min * " + leftMinutes.data.days_per_week + " day"} </p>
                                    </div>
                                    <button className='btn ms-700 bg-sky text-white px-2 py-2 fs-14 w-100 my-2' onClick={() => history.push('/user/dashboard/subscription')}> {t('userDash.header.changePlan')}</button>
                                </div>
                            </div>
                        }
                    </div>
                    }

                    <div className='p-rel'>
                        <p id="step-today-classes" className="mr-2 icon-border icon-box all-center px-2 py-1 calendar-icon-hover pointer tx-green" onMouseOver={() => { setState({ ...state, upcomingClass: true, showleftminutes: false }); getTodayClasses(helper.getid(), moment().format('YYYY-MM-DD')) }}>
                            <img src={calendarIcon} style={{ width: '25px' }} alt="calendar" />
                        </p>
                        {state.upcomingClass && <div onMouseLeave={() => setState({ ...state, showleftminutes: false, upcomingClass: false })} className='p-abs upcoming-class-popup'>
                            <div className='upcoming-class-card my-2 p-3' style={{ maxHeight: '380px', overflowY: 'auto' }}>
                                <h4 className='ms-500 text-white'>{t('userDash.header.upCalls')}</h4>

                                <section className='my-2'>
                                    {
                                        upcomingClassData.map((data, index) =>

                                            <div key={`tutor${index}`} className={` tutor-red-card pointer b-grey bg-white mb-2 px-2`}
                                                onClick={() => {
                                                    if (helper.isUserCanJoinClass({ classDate: moment(data.date).format('YYYY-MM-DD'), startTime: data.start_time })) {
                                                        history.push(`/user/class-room/${data.channel_name}`)
                                                    }
                                                    else {
                                                        alert("please wait for class time")
                                                    }
                                                }}>
                                                <section className='d-flex'>
                                                    <div className="all-center my-2">
                                                        <div className='p-rel mr-2'>
                                                            <img style={{ height: '40px', width: '40px' }} className="rounded-circle mx-1" src={data.profilePicture ? data.profilePicture : avatar} alt="avatar" />
                                                            <div className={`${data.isOnline ? "online-tag" : "offline-tag"} p-abs bt-0 rt-0`}></div>
                                                        </div>
                                                    </div>
                                                    <div className='w-100 d-flex justify-content-between'>
                                                        <div className="my-2 pl-2">
                                                            <p className="text-capitalize fs-14 f-fred">{data.name}</p>
                                                            {/* <p className="lh-18 fs-14 ms-500">Tutor from <span className='ms-900 text-capitalize'>{tutor.data.from}</span></p> */}
                                                            <div className="d-flex align-items-center">
                                                                <div className="d-inline-block py-0 my-0 mr-1">
                                                                    <ReactStars
                                                                        edit={false}
                                                                        value={data.ratings ? parseFloat(data.ratings).toFixed(1) : 0}
                                                                        count={5}
                                                                        size={12}
                                                                        isHalf={true}
                                                                        emptyIcon={<i className="far fa-star"></i>}
                                                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                                        fullIcon={<i className="fa fa-star"></i>}
                                                                        classNames="my-0 py-0"
                                                                        activeColor="#ffcb00"
                                                                    />
                                                                </div>
                                                                <span className="tx-black ms-700 fs-12">{data.ratings ? parseFloat(data.ratings).toFixed(1) : 0}</span>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex justify-content-end">
                                                            <span className="p-2">
                                                                <img width="20px" height="20px" className="rounded-circle" src={data.flag_uri} alt="flag" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className='fs-12 py-2'>
                                                    <p className='ms-700'>{t('tutorDash.calendar.scheduleOn')}</p>
                                                    <p className='ms-900'>{moment(data.date).format('MMMM DD, YYYY')} at {moment(data.start_time, 'hh:mm').format('hh : mm A')}</p>
                                                </section>


                                            </div>
                                        )
                                    }

                                </section>

                                {
                                    upcomingClassData.length === 0 ?
                                        <section>
                                            <p className='my-2 ms-500 text-white'>{t('userDash.header.noLesson')}</p>
                                            <button className='btn schedule-btn ms-700 px-2 py-2 fs-14' onClick={() => history.push('/user/dashboard/calendar')}> <i className='fas fa-plus'></i> {t('userDash.header.scheduleClass')}</button>
                                        </section>
                                        :
                                        <section>
                                            <button className='btn schedule-btn ms-700 px-2 my-2 py-2 fs-14' onClick={() => history.push('/user/dashboard/calendar')}> <i className='fas fa-plus'></i>  {t('userDash.header.scheduleClass')}</button>
                                        </section>
                                }

                            </div>
                        </div>}
                    </div>

                    <p id="step-notification" className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onMouseOver={() => setState({ ...state, showleftminutes: false, upcomingClass: false })} onClick={() => history.push('/user/dashboard/notification')} >
                        <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                    </p>
                    <p id="step-chat" className='mr-2 all-center icon-box icon-border px-2 py-1 d-lg-block d-md-block d-none  pointer tx-green' onMouseOver={() => setState({ ...state, showleftminutes: false, upcomingClass: false })} onClick={() => history.push('/user/dashboard/chat')}>
                        <img src={chatIcon} style={{ width: '25px' }} alt="chat"/>
                    </p>
                    <div  className="mr-2 icon-box icon-border  d-lg-flex d-md-flex d-none py-1 all-center" >
                        <img width="25px" height="25px" className="rounded-circle" src={storeData.setFlag} alt="flag" />
                    </div>
                    <span id="step-profile" className="mr-2 all-center icon-box    pointer" onClick={() => history.push('/user/dashboard/profile')}>
                        <img width="35px" height="35px" className="rounded-circle mt-1" src={storeData.profilePic ? storeData.profilePic : avatar} alt="pic" />
                    </span>
                    <div id="step-moreinfo" className="mr-2 mt-2">
                        <Tooltip title="More">
                            <div className='pointer' >
                                <FaEllipsisV size={20} onClick={(e) => { setNav({ ...nav, open: true, ref: e.currentTarget }) }} className="inactive-icon-color" />
                            </div>
                        </Tooltip>
                    </div>
                </section>
            </div>

            {/* Change Password */}
            <UserPops.ChangePassword
                open={state.changePass}
                close={() => setState({ ...state, changePass: false })}
            />

            {
                openMenu && <Menubar tab={page} cb={() => setOpenMenu(false)} />
            }

            {/* Handle 15 min POPUP */}
            <AlertClassPop />

            {/* Mobile view navigation */}
            <Menu
                anchorEl={nav.ref}
                open={nav.open}
                onClose={(e) => setNav({ ...nav, open: false, ref: e.currentTarget })}>
                <div className='mobile-nav'>
                    <ul>
                        <li> 
                        <div className="d-lg-none d-md-none d-flex  py-1 " >
                            <img width="25px" height="25px" className="rounded-circle mr-2" src={storeData.setFlag} alt="flag" />
                            <p>{storeData.name && storeData.name.split(" ").length > 0 && storeData.name.split(" ")[0]}</p>
                        </div>
                       
                        </li>
                        <li><LanguageTranslator /></li>
                        <li><ReferralClasses
                            isShow={!(state.showleftminutes || state.upcomingClass)}
                            onMouseOver={() => { setState({ ...state, showleftminutes: false, upcomingClass: false }) }}
                        /></li>
                    </ul>
                </div>
            </Menu>
            <UserTour steps={userSteps}/>
        </>
    )
}
